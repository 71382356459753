<template>
  <v-dialog
    v-model="dialog"
    max-width="1000"
    :persistent="load"
    scrollable
  >
    <v-card :disabled="load">
      <v-card-title>
        {{  nuevo ? 'Nuevo' : (editar ? 'Editar' : 'Detalle')}} cliente
        <v-spacer></v-spacer>
        <template v-if="!nuevo && p_crear && cliente.fraude != 1">
          <v-switch
            v-model="editar"
            label="Editar"
            class="d-flex align-top mt-0"
            hide-details
            dense
          ></v-switch>
          <v-divider
            class="mx-3"
            vertical
          ></v-divider>
        </template>
        <v-btn
          icon
          @click="dialog = false"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pb-3">
        <v-form ref="form">
          <v-row class="pt-8">
            <v-col cols="12" sm="4" md="3" class="d-flex py-0">
              <v-text-field
                v-model.trim="cliente.apellido"
                label="Apellido"
                :rules="editar ? [rules.required] : []"
                :readonly="!editar"
                :filled="!editar"
                :autofocus="editar"
                validate-on-blur
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="3" class="d-flex py-0">
              <v-text-field
                v-model.trim="cliente.nombre"
                label="Nombre"
                :rules="editar ? [rules.required] : []"
                :readonly="!editar"
                :filled="!editar"
                validate-on-blur
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="3" class="py-0">
              <v-autocomplete
                v-model="cliente.cond_iva"
                label="Condición IVA"
                item-text="condicion_iva_nombre"
                item-value="condicion_iva_codigo"
                :items="cond_iva"
                :rules="editar ? [rules.required] : []"
                :readonly="!editar"
                :filled="!editar"
                validate-on-blur
                outlined
                dense
                @change="setearTipoDoc()"
              ></v-autocomplete>
            </v-col>
            <v-col cols="6" sm="3" md="2" class="py-0">
              <v-autocomplete
                v-model="cliente.tipo_doc"
                label="Tipo documento"
                item-text="nombre"
                item-value="id"
                :items="tipos_doc"
                :rules="editar ? [rules.required] : []"
                :readonly="!editar || cliente.cond_iva == null || bloquear_tipo_doc"
                :filled="!editar || cliente.cond_iva == null || bloquear_tipo_doc"
                validate-on-blur
                outlined
                dense
                @change="cliente.num_doc = null; cliente.cuit = null"
              ></v-autocomplete>
            </v-col>
            <v-col cols="6" sm="3" md="2" class="py-0">
              <v-text-field-integer
                v-model="cliente.cuit"
                label="CUIT"
                v-bind:properties="{
                  'validate-on-blur': true,
                  rules: editar && cliente.tipo_doc == 1 ? [rules.required] : [],
                  readonly: !editar || cliente.tipo_doc != 1,
                  filled: !editar || cliente.tipo_doc != 1,
                  outlined: true,
                  dense: true
                }"
                v-bind:options="{
                  inputMask: '##-########-#',
                  outputMask: '###########',
                  empty: null
                }"
                @blur="calcularDocumento()"
              />
            </v-col>
            <v-col cols="6" sm="3" md="2" class="py-0">
              <v-text-field
                v-model="cliente.num_doc"
                label="Nº Documento"
                :rules="editar && cliente.tipo_doc != 1 && cliente.tipo_doc != null ? [rules.required] : []"
                :readonly="!editar"
                :filled="!editar"
                validate-on-blur
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="6" sm="3" md="3" class="py-0">
              <v-autocomplete
                v-model="cliente.provincia"
                label="Provincia"
                item-text="nombre"
                item-value="codigo"
                :items="provincias_tmp"
                :readonly="!editar"
                :filled="!editar"
                :clearable="editar"
                outlined
                dense
                @change="cliente.localidad = null; getLocalidades()"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="4" md="5" class="py-0">
              <v-autocomplete
                v-model="cliente.localidad"
                label="Localidad"
                item-text="nombre"
                item-value="codigo"
                :items="localidades"
                :readonly="!editar"
                :filled="!editar"
                :clearable="editar"
                outlined
                dense
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="4" class="py-0">
              <v-text-field
                v-model="cliente.direccion"
                label="Domicilio"
                :readonly="!editar"
                :filled="!editar"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" class="py-0">
              <v-text-field
                v-model="cliente.email"
                label="Email"
                :rules="editar ? [rules.email] : []"
                :readonly="!editar"
                :filled="!editar"
                validate-on-blur
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="6" sm="3" md="2" class="py-0">
              <v-text-field
                v-model="cliente.telefono"
                label="Telefono"
                :readonly="!editar"
                :filled="!editar"
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-expand-transition>
            <v-row v-if="!nuevo && !editar" class="pb-2">
              <v-col cols="12" class="pt-0">
                <v-card outlined flat>
                  <v-card-title class="py-2 body-1">Log</v-card-title>
                  <v-card-text>
                    <v-row class="d-flex justify-center">
                      <v-col cols="6" sm="3">
                        <v-text-field
                          v-model="cliente.usuario_grabacion"
                          label="Usuario grabación"
                          hide-details
                          outlined
                          readonly
                          filled
                          dense
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6" sm="3" md="2">
                        <v-text-field
                          v-model="cliente.fecha_grabacion"
                          label="Fecha grabación"
                          hide-details
                          outlined
                          readonly
                          filled
                          dense
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6" sm="3">
                        <v-text-field
                          v-model="cliente.usuario_log"
                          label="Usuario log"
                          hide-details
                          outlined
                          readonly
                          filled
                          dense
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6" sm="3" md="2">
                        <v-text-field
                          v-model="cliente.fecha_log"
                          label="Fecha log"
                          hide-details
                          outlined
                          readonly
                          filled
                          dense
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col v-if="escaneos.length > 0" cols="12" class="pt-0">
                <v-card outlined flat>
                  <v-card-title class="py-2 body-1">Historial de escaneos de DNI</v-card-title>
                  <v-card-text class="pb-0">
                    <v-data-table
                      class="cebra mb-2"
                      sort-by="id"
                      :headers="headers_dni"
                      :items="escaneos"
                      :items-per-page="-1"
                      hide-default-footer
                      dense
                    ></v-data-table>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-expand-transition>
        </v-form>
      </v-card-text>
      <v-card-actions class="d-flex justify-end pb-4">
        <v-btn
          class="mr-2"
          :color="editar ? 'error' : 'secondary'"
          :disabled="load"
          @click="dialog = false"
        >
          {{ editar ? 'Cancelar' : 'Volver' }}
        </v-btn>
        <BtnConfirmar
          v-if="editar"
          :loading="load"
          @action="guardar()"
        />
      </v-card-actions>
    </v-card>
    <!-- DATA TABLE COINCIDENCIAS -->
    <v-dialog
      v-model="mostrar_coincidencias"
      width="900"
    >
      <v-card class="pt-4">
        <v-card-text class="pa-0">
          <v-alert
            v-if="coincidencias.length != 0"
            class="text-center body-1 mx-sm-6 mx-2"
            type="info"
            border="left"
            text
          >
            Se encontr{{ coincidencias.length == 1 ? 'ó' : 'aron' }}
            <strong>{{ coincidencias.length }} coincidencia{{ coincidencias.length != 1 ? 's' : '' }}</strong> para el nuevo cliente
            <strong>{{ cliente.apellido.toUpperCase() }} {{ cliente.nombre.toUpperCase() }}</strong>
          </v-alert>
          <v-data-table
            class="cebra mt-2"
            sort-by="nombre"
            :headers="headers"
            :items="coincidencias"
            :items-per-page="-1"
            hide-default-footer
            dense
          ></v-data-table>
        </v-card-text>
        <v-card-actions class="d-flex justify-end pb-4">
          <v-btn
            class="mr-2"
            color="warning"
            @click="mostrar_coincidencias = false"
          >
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<script>
import moment from 'moment'
import { mapState } from 'vuex'
import { REGEX_EMAIL, get_nombre_obj_arr } from '../../../util/utils'
import BtnConfirmar from '../../util/BtnConfirmar'

export default {
  data () {
    return {
      load: false,
      nuevo: false,
      editar: false,
      bloquear_tipo_doc: false,
      mostrar_coincidencias: false,
      escaneos: [],
      tipos_doc: [],
      localidades: [],
      coincidencias: [],
      headers: [
        { text: 'Nombre', value: 'nombre', sortable: false },
        { text: 'Nº Documento', value: 'num_doc', sortable: false },
        { text: 'CUIT', value: 'cuit', sortable: false },
        { text: 'Tipo documento', value: 'tipo_doc', sortable: false },
        { text: 'Condición IVA', value: 'cond_iva', sortable: false },
        { text: 'Usuario grabación', value: 'usuario_grabacion', sortable: false },
        { text: 'Fecha grabación', value: 'fecha_grabacion', sortable: false }
      ],
      headers_dni: [
        { text: 'Sexo', value: 'sexo', sortable: false },
        { text: 'Fecha nacimiento', value: 'nacimiento', sortable: false },
        { text: 'Nº trámite', value: 'num_tramite', sortable: false },
        { text: 'Fecha trámite', value: 'fecha_tramite', sortable: false },
        { text: 'Ejemplar', value: 'ejemplar', sortable: false },
        { text: 'Fecha escaneo', value: 'fecha_grabacion', sortable: false },
        { text: 'Usuario escaneo', value: 'usuario_grabacion', sortable: false }
      ],
      cliente: {},
      rules: {
        required: value => !!value || 'Campo requerido',
        email: value => {
          if (value) return REGEX_EMAIL.test(value) || 'Email no válido'
          else return true
        }
      }
    }
  },
  async created () {
    this.$store.state.loading = true
    await this.$store.dispatch('clientes/permiso_fraude')
    await this.$store.dispatch('clientes/permiso_crear')
    await this.$store.dispatch('get_provincias_tmp')
    await this.$store.dispatch('get_cond_iva')
    await this.$store.dispatch('get_tipos_doc')
      .then((data) => {
        this.tipos_doc = data.tipos_doc
      })
      .catch(error => {
        this.$store.dispatch('show_snackbar', {
          text: error.message,
          color: 'error'
        })
      })
    this.$store.state.loading = false
  },
  props: {
    value: Boolean,
    p_cliente: Object
  },
  computed: {
    ...mapState('clientes', ['p_crear', 'p_fraude']),
    ...mapState(['cond_iva', 'provincias_tmp']),
    dialog: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  components: {
    BtnConfirmar
  },
  watch: {
    async dialog (val) {
      if (val) {
        await new Promise(resolve => setTimeout(resolve, 1))
        // ver / editar
        if (this.p_cliente) {
          this.nuevo = false
          this.cliente = JSON.parse(JSON.stringify(this.p_cliente))
          await this.getLocalidades()
          // obtengo los escaneos del dni
          this.$store.state.loading = true
          await this.$store.dispatch('clientes/get_escaneos_dni', this.cliente.codigo)
            .then((res) => {
              this.escaneos = res.data
            })
            .catch(error => {
              this.$store.dispatch('show_snackbar', {
                text: error.message,
                color: 'error'
              })
            })
          this.$store.state.loading = false
        }
        // nuevo
        else {
          this.nuevo = true
          this.editar = true
          this.cliente = {
            apellido: null,
            nombre: null,
            cuit: null,
            num_doc: null,
            tipo_doc: null,
            cond_iva: null,
            provincia: null,
            localidad: null,
            direccion: null,
            email: null,
            telefono: null,
            fraude: 0,
            observaciones: null,
            usuario_grabacion: this.$store.state.username,
            usuario_log: null,
            fecha_log: null
          }
        }
      } else {
        this.editar = false
        this.$refs.form.resetValidation()
        this.coincidencias = []
        this.localidades = []
        this.escaneos = []
      }
    },
    async editar (val) {
      if (!val && this.dialog) {
        // vuelve a dejar los datos como estaban
        const provincia_vieja = JSON.stringify(this.cliente.provincia)
        this.cliente = JSON.parse(JSON.stringify(this.p_cliente))
        if (provincia_vieja != JSON.stringify(this.p_cliente.provincia)) {
          await this.getLocalidades()
        }
      }
    }
  },
  methods: {
    async guardar () {
      if (this.$refs.form.validate()) {
        // NUEVO
        if (this.nuevo) {
          this.$store.state.loading = true
          
          // valido que no existan coincidencias
          let consultaCoincide = await this.$store.dispatch('clientes/get_coincidencias', {
            codigo: null,
            cuit: this.cliente.cuit,
            documento: this.cliente.num_doc
          })

          if (consultaCoincide.exito == 1) {
            this.coincidencias = consultaCoincide.data
            if (consultaCoincide.size > 0) {
              this.$store.state.loading = false
              return this.mostrar_coincidencias = true
            }
          } else {
            this.$store.state.loading = false
            return this.$store.dispatch('show_snackbar', {
              text: consultaCoincide.message,
              color: 'error'
            })
          }

          // creo el cliente
          await this.$store.dispatch('clientes/nuevo_cliente', this.cliente)
            .then((result) => {
              // seteo los datos del nuevo cliente
              this.setearDatos()
              this.cliente.codigo = result.id
              this.cliente.fecha_grabacion = moment(new Date()).format('DD/MM/YYYY')
              // emito la accion de nuevo cliente
              this.$emit('nuevo', this.cliente)
              // cierro el modal y muestro mensaje de exito
              this.dialog = false
              this.$swal.fire({
                icon: 'success',
                title: result.message
              })
            }).catch((err) => {
              this.$store.dispatch('show_snackbar', {
                text: err.message,
                color: 'error'
              })
            })
          this.$store.state.loading = false
        }
        // EDITAR
        else {
          // solo edito si cambio algo
          if (JSON.stringify(this.cliente) != JSON.stringify(this.p_cliente)) {
            this.$store.state.loading = true
          
            // valido que no existan coincidencias
            let consultaCoincide = await this.$store.dispatch('clientes/get_coincidencias', {
              codigo: this.cliente.codigo,
              cuit: this.cliente.cuit,
              documento: this.cliente.num_doc
            })

            if (consultaCoincide.exito == 1) {
              this.coincidencias = consultaCoincide.data
              if (consultaCoincide.size > 0) {
                this.$store.state.loading = false
                return this.mostrar_coincidencias = true
              }
            } else {
              this.$store.state.loading = false
              return this.$store.dispatch('show_snackbar', {
                text: consultaCoincide.message,
                color: 'error'
              })
            }

            // edito el cliente
            await this.$store.dispatch('clientes/editar_cliente', this.cliente)
              .then((result) => {
                // seteo los datos del cliente
                this.setearDatos()
                this.cliente.fecha_log = moment(new Date()).format('DD/MM/YYYY')
                this.cliente.usuario_log = this.$store.state.username
                // emito la accion de editar cliente
                this.$emit('editar', this.cliente)
                // cierro el modal y muestro mensaje de exito
                this.dialog = false
                this.$swal.fire({
                  icon: 'success',
                  title: result.message
                })
              }).catch((err) => {
                this.$store.dispatch('show_snackbar', {
                  text: err.message,
                  color: 'error'
                })
              })

            this.$store.state.loading = false
          }
          else {
            this.editar = false
            this.$store.dispatch('show_snackbar', {
              text: 'No se realizaron cambios en el cliente: los campos nuevos son iguales a los anteriores',
              color: 'info',
              timeout: 4000
            })
          }
        }
      }
    },
    async getLocalidades () {
      this.localidades = []
      if (this.cliente.provincia) {
        this.$store.state.loading = true
        await this.$store.dispatch('get_localidades_tmp', this.cliente.provincia)
          .then((result) => {
            this.localidades = result.data
          }).catch((err) => {
            this.$store.dispatch('show_snackbar', {
              text: err.message,
              color: 'error'
            })
          })
        this.$store.state.loading = false
      }
    },
    setearDatos () {
      this.cliente.nombre = this.cliente.nombre.toUpperCase()
      this.cliente.apellido = this.cliente.apellido.toUpperCase()
      this.cliente.name = this.cliente.apellido + ' ' + this.cliente.nombre
      this.cliente.cond_iva_nombre = get_nombre_obj_arr(this.cond_iva, 'condicion_iva_codigo', this.cliente.cond_iva, 'condicion_iva_nombre')
      this.cliente.localidad_nombre = get_nombre_obj_arr(this.localidades, 'codigo', this.cliente.localidad, 'nombre')
      this.cliente.provincia_nombre = get_nombre_obj_arr(this.provincias_tmp, 'codigo', this.cliente.provincia, 'nombre')
      this.cliente.tipo_doc_nombre = get_nombre_obj_arr(this.tipos_doc, 'id', this.cliente.tipo_doc, 'nombre')
      if (this.cliente.direccion) this.cliente.direccion = this.cliente.direccion.toUpperCase()
    },
    setearTipoDoc () {
      this.cliente.tipo_doc = null
      this.bloquear_tipo_doc = false
      const condicion_iva = this.cond_iva.find(ci => ci.condicion_iva_codigo == this.cliente.cond_iva)
      if (condicion_iva && condicion_iva.tipo_doc != null) {
        this.cliente.tipo_doc = condicion_iva.tipo_doc
        this.bloquear_tipo_doc = true
      }
    },
    calcularDocumento () {
      this.cliente.num_doc = this.cliente.cuit.toString().substring(2, 10)
    }
  }
}
</script>